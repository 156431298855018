const SDK = {
  BASE_URL: "https://minimis.cloud",
  SOCKET_URL: "https://ios.minimis.cloud",
  STRIPE_KEY: 'pk_test_51Mq48pCHhPy4gmdpAZQj1yYXf95jzpl3KfzkyjlR0ycFQRPB5GkG1TBXhoC0dRJK4IdrvKO2MGp6vtXGCvM3ZObr004SL3pW7o',
  FB_PIXEL_ID: "672056548014780",
  TIK_TOK_PIXEL_ID: "CQOB3TBC77U11VCBB04G",
  FB_CURRNECY: "USD",
  FB_PRICE: "29",
  APPLE_PAY_PRICE: 2900,
  APPLE_PAY_CURRENCY: "usd"
};

export default SDK;
